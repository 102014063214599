<template>
  <b-container class="pt-3">
    <b-form @submit.prevent="updateMedia">
      <b-row>
        <b-col>
          <b-row align-v="end">
            <b-col cols="*" class="ml-3">
              <h2 class="m-0 p-0">Edit Media</h2>
            </b-col>
            <b-col v-if="media">
              <font size="2.3" class="text-muted">
                <span
                  @click="updateClipboard(media.url)"
                  style="cursor: pointer"
                >
                  {{ media.url }} (Click to Copy)
                </span>
              </font>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="*" class="mr-3">
          <b-button variant="primary" type="submit" :disabled="updating"
            >Update <b-spinner small v-if="updating"
          /></b-button>
        </b-col>
      </b-row>
      <hr class="mt-1 mb-3" />

      <b-row>
        <b-col>
          <b-form-input
            placeholder="Enter name"
            v-model="name"
            autocomplete="off"
            class="mb-3"
            required
          ></b-form-input>
        </b-col>

        <b-col>
          <b-form-select
            v-model="media_type"
            :options="options"
            class="mb-3"
            disabled
          ></b-form-select>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-textarea v-model="description" class="mb-3">
          </b-form-textarea>
        </b-col>
      </b-row>
    </b-form>

    <b-row>
      <b-col>
        <b-img-lazy
          v-if="url.length != 0 && media_type == 'image'"
          :src="url"
          fluid
          class="mb-3"
        />
        <video-player
          v-if="url.length != 0 && media_type == 'video'"
          :options="videoOptions"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import VideoPlayer from "@/components/VideoPlayer.vue";

export default {
  components: {
    VideoPlayer,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      updating: false,
      url: "",
      name: "",
      description: "",
      media_type: null,
      media: null,
      options: [
        { value: null, text: "Please select a media type" },
        { value: "image", text: "Image" },
        { value: "video", text: "Video" },
      ],
      videoOptions: {
        autoplay: false,
        controls: true,
        fluid: true,
        sources: [],
      },
    };
  },
  methods: {
    updateClipboard(newClip) {
      navigator.clipboard.writeText(newClip).then(
        function () {
          /* clipboard successfully set */
          this.showToast(
            "URL succesfully copied to clipboard!",
            "Success!",
            "success"
          );
        }.bind(this),
        function () {
          /* clipboard write failed */
          this.showToast(
            "Could not copy URL to clipboard!",
            "Error!",
            "danger"
          );
        }.bind(this)
      );
    },
    async updateMedia() {
      this.updating = true;
      try {
        await this.$http.put(
          this.$store.state.apiURL + "/media/" + this.id,
          {
            name: this.name,
            description: this.description,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );
      } catch (error) {
        this.handleError(error);
      }
      this.updating = false;
    },
  },
  async created() {
    let response = await this.$http.get(
      this.$store.state.apiURL + "/media/" + this.id,
      {
        headers: {
          Authorization: "Bearer " + this.$store.state.JWT,
        },
      }
    );

    this.media = response.data;
    this.url = this.media.url;
    this.media_type = this.media.media_type;
    this.name = this.media.name;
    this.description = this.media.description;

    this.videoOptions.sources = [
      {
        src: this.url,
      },
    ];
  },
};
</script>

<style>
</style>